.property-overview {
	/*padding: 20px;*/
	padding: 10px;
	max-height: 100%;
	overflow-y: auto;
}

.overview-cards-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.property-overview .overview-card-container {
	margin-top: 4px;
}

.property-overview h2 {
	font-size: 18px;
}

.property-overview .overview-title h2 {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 0;
	margin-left: 5px;
}

.property-overview h1 {
	font-size: 24px;
}

.overview-header {
	display: flex;
	justify-content: space-between;
}

.overview-header .button-wrapper {
	display: flex;
	align-items: center;
}

.overview-header .button-wrapper button {
	/*margin: 0 5px;*/
	margin-right: 10px;
}




/* desktop layout */
@media (min-width: 901px) {
	.overview-header {
		margin: 0 10px;
		align-items: center;
	}

	.property-overview .use-policy-wrapper {
		margin: 15px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.property-overview {
		padding: 0;
	}

	.property-overview .button-wrapper {
		margin-left: 15px;
	}
	.property-overview .button-wrapper button {
		margin: 4px;
	}

	.property-overview .overview-title h2 {
		margin-bottom: 10px;
		margin-left: 0;
	}

	.overview-header {
		padding: 0 20px;
		flex-direction: column;
		margin-bottom: 5px;
	}

	.overview-card-container {
		margin: 10px 0;
		width: 100%;
	}

	.overview-card-container .overview-card {
		width: 100%;
	}

	.overview-card-container .overview-card-body {
		height: auto;
	}

	.property-overview .use-policy-wrapper {
		margin: 15px 0;
		margin-left: 15px;
	}
}