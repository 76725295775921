.amounts-due {
	background-color: white;
	border: 1px solid var(--border-color);
	border-radius: calc(.65rem - 1px);

	padding: 8px 15px;
	margin: 10px;
}

.dark-mode .amounts-due {
	background-color: var(--dark-mode-bg);
	color: var(--dark-mode-color);
}

.amounts-due .amounts-due-header {
	margin-bottom: 0px;
	font-size: 16px;
	font-weight: bold;
}

.amounts-due table thead {
	font-weight: bold;
}

.amounts-due table {
	margin-bottom: 0;
/*	max-width: 800px;*/
}

.amounts-due table td {
	padding: 4px 8px;
}
.amounts-due table td:not(:first-child) {
	text-align: right;
}


.dark-mode .amounts-due table td {
	border-color: var(--dark-mode-border-color);
}


/* desktop layout */
@media (min-width: 1231px) {
	.amounts-due {
		
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.amounts-due {
		
	}

}