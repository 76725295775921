.left-nav { 
	height: calc(100% - 111px);
	position: fixed;
	width: 280px;
	background: white;
	border-right: 1px solid var(--border-color);
	overflow-y: auto;
	overflow-x: hidden;
/*	padding-top: 20px;*/
	padding-bottom: 40px;
}

.left-nav .property-image-wrapper {
	text-align: center;
	margin: 10px 0;
}
.left-nav .property-image {
/*	max-width: 250px;*/
	max-height: 250px;
	margin: 0 auto;
	object-fit: contain;
}

.dark-mode .left-nav {
	background-color: var(--dark-mode-bg);
}

.left-nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.left-nav ul li {
	margin: 0;
	padding: 0;
}

.left-nav span.left-nav-button,
.left-nav a.left-nav-button,
.left-nav button.left-nav-button {
	text-align: left;
	width: 100%;
	display: block;
	border: none;
	background-color: transparent;
	display: block;
	padding: 5px 20px 5px 20px;
	color: #212529;
	text-decoration: none;
}


.dark-mode .left-nav span.left-nav-button,
.dark-mode .left-nav a.left-nav-button,
.dark-mode .left-nav button.left-nav-button {
	color: var(--dark-mode-color);
}

.left-nav span.left-nav-button.link-disabled {
	color:  var(--bs-gray-600);
}
.left-nav span.left-nav-button.link-disabled:hover {
	color: white;
	background-color: var(--bs-secondary);
}

.left-nav span.left-nav-button .sidebar-text,
.left-nav a.left-nav-button .sidebar-text,
.left-nav button.left-nav-button .sidebar-text {
	max-width: 180px;
}

.left-nav span.left-nav-button:hover,
.left-nav a.left-nav-button:hover,
.left-nav button.left-nav-button:hover {
	border: none;
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
	color: #fff;
}

.left-nav .collapse-container span.left-nav-button,
.left-nav .collapse-container a.left-nav-button,
.left-nav .collapse-container button.left-nav-button {
	text-align: left;
	width: 100%;
	display: block;
	border: none;
	background-color: transparent;
	display: block;
	padding: 5px 20px 5px 50px;
	text-decoration: none;
}

.left-nav .collapse-container span.left-nav-button:hover,
.left-nav .collapse-container a.left-nav-button:hover,
.left-nav .collapse-container button.left-nav-button:hover {
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
}

.left-nav .collapse-button {
	padding: 5px 10px 5px 20px;
}

.left-nav .collapse-container span.link-disabled, 
.left-nav .collapse-container a {
	display: block;
	padding: 5px 20px 5px 35px;
	text-decoration: none;
}

.left-nav .collapse-button:hover {
	/*background-color: #2c2e3e;*/
	background-color: var(--bs-primary);
	color: #fff;
}

.left-nav li.divider {
	margin: 10px 20px 10px 20px;	
	border-bottom: 1px solid var(--border-color);
}

.left-nav .address-search {
	margin: 0px 20px 20px 20px;
	width: auto;
}

.left-nav .sidebar-button-content {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	padding-right: 13px;
}

.left-nav .collapse-button .sidebar-button-content {
	padding-right: 9px;
	width: 100%;
}
