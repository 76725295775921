.overview-card-container {
	margin: 10px;
}

.overview-card {
	/*width: 350px;*/
	/*min-width: 350px;*/
	/*max-width: 450px;*/
}


.overview-card-body {
	height: 400px;
	overflow-y: auto;
	position: relative;
}

.overview-card-body.is-loading {
	overflow-y: hidden;
}

.overview-card-body .spinner-container{
	position: absolute;
	width: 100%;
	background: white;
	top: 0;
	left: 0;
}
.dark-mode .overview-card-body .spinner-container{
	background: var(--dark-mode-bg);
}


/* desktop layout */

@media (min-width: 901px) {
	.overview-card-container {
		width: 300px;
		flex-grow: 1;
/*		width: calc(100% / 2 - 23px);*/
	}
}

@media (min-width: 1260px) {
	.overview-card-container {
/*		width: calc(100% / 3 - 23px);*/
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.overview-card-container {
		width: calc(100%);
	}
}