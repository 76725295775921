.paginator {
	background-color: white;
}

.dark-mode .paginator,
.dark-mode .paginator .page-link,
.dark-mode .paginator .pagination {
	background-color: var(--dark-mode-bg);
	color: var(--dark-mode-color);
}

.pagination .page-item:not(.disabled) {
	cursor: pointer;
}

.pagination {
	background-color: #fff;
	/*border-top:  1px solid #d6d6d6;*/
	border-top: 1px solid var(--border-color);
	padding: 15px 20px 20px 20px;
	overflow-x: auto;
	margin-bottom: 0;
}

.pagination .page-item.active .page-link {
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);	
}
.pagination .page-link {
	color: var(--bs-primary);
	transition: none;
}
.pagination .page-link:hover {
	color: white;
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);
}


/* desktop layout */
@media (min-width: 901px) {
	.pagination {
		max-width: calc(100% - 140px);
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.pagination {
/*		max-width: calc(100% - 80px);*/
	}
}
