.data-table-container {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.data-table-container .table-wrapper {
	/*height: calc(100% - 152px);*/
	height: 100%;
	overflow: scroll;
}

.data-table-container .navbar {
	border-bottom: 1px solid var(--border-color);
}

.data-table-container .data-set-name {
	font-size: 18px;
	font-weight: bold;
	margin-right: 8px;
}
.data-table-container .data-source-name {
	font-size: 14px;
}
.data-table-container .data-source-name a:hover {
	text-decoration: none;
	color: var(--bs-active-primary);
}
.dark-mode .data-table-container .data-source-name a {
	color: var(--dark-mode-color);
}
.dark-mode .data-table-container .data-source-name a:hover {
	color: var(--bs-active-primary);
}

.data-table-container h4 {
	font-size: 18px;
	margin-top: 10px;
	padding-left: 12px;
	text-align: center;
}

.data-table-container .table-controls {
	display: flex;
}

.data-table-container .data-table-search {
	width: 240px;
	font-size: 14px;
}

.data-table-container .spinner-container {
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgb(255,255,255, .9);
}
.dark-mode .data-table-container .spinner-container {
	background: rgba(32, 32, 32, 0.97);
}

.data-table-container .paginator {
	height: 70px;
}


.data-table-container .table-header-sort-button {
	color: var(--text-black);
}
.dark-mode .data-table-container .table-header-sort-button {
	color: var(--dark-mode-color);
}



/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.table-controls {
		flex-direction: column;
		width: 100%;
		margin-top: 5px;
	}

	.data-table-container .data-table-search {
		width: 100%;
		margin-bottom: 10px;
	}

	.data-table .nothing-found-row {
		text-align: left;
		padding-left: 30vw;
	}

	.data-table-container .table-wrapper {
		/* 125px for filters, 70px for paginator */
		min-height: calc(100vh - var(--app-header-height) - var(--app-toolbar-height) - 125px - 70px);
	}
}

/* tablet layout */
@media (min-width: 700px) and (max-width: 900px) {
	.table-controls {
		flex-direction: row;
		width: 100%;
		margin-top: 5px;
	}
	.table-controls .filter-button {
		height: 30px;
	}
	.table-controls .data-table-search {
		padding: 5px 10px;
	}
}
