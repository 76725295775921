.property-search-page {
	max-height: calc(100% - var(--app-header-height));
	overflow: auto;
	padding-bottom: 40px;
}

/*.property-search-page img.logo{
	max-width: 100%;
	max-height: 200px;
}*/

body:not(.dark-mode) .property-search-page svg g#VIOLERTS_TEXT path {
	fill: #2b2b2b !important;
}
.property-search-page svg g#VIOLERTS_SIGNAL_ICON path {
	fill: #25d000 !important;
}
.property-search-page svg {
	/* these aren't the real aspect ratio, the logo doesn't distort */
	height: 110px;
	width: 100%;
}

.property-search-page-content {
	margin: auto;
	margin-top: 0px;
	max-width: 400px;
	margin-bottom: 60px;
}

.property-search-page-content h1 {
	margin-bottom: 40px;
	white-space: nowrap;
}


.property-search-page .contact-info {
	margin-top: 80px;
	text-align: center;
}

.property-search-page div {
	margin-bottom: 10px;
}


/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.property-search-page-content {
		padding: 0 20px;
		text-align: center;
	}
	.property-search-page-content .address-search {
		margin: 0;
	}
}