.app-header {
	height: var(--app-header-height);
}
.app-header.top-nav {
	background-color: #2c2e3e;
}


/** logo **/

.app-header .navbar-brand.svg-logo-wrapper {
	padding: 0;
	margin-right: 0;
}
.app-header .navbar-brand.svg-logo-wrapper svg {
	height: 40px;
	margin-left: -26px;
	margin-right: -32px;
}
.app-header .navbar-brand.svg-logo-wrapper svg g#VIOLERTS_SIGNAL_ICON path{
	fill: #25d000 !important;
}

/** contact icons **/
.app-header .contact-icon-wrapper a {
	color: var(--bs-primary);
}
.app-header .contact-icon-wrapper {
	font-size: 22px;
	margin-left: 20px;
	vertical-align: middle;
}
.app-header .call-us {
	cursor: pointer;
}


/** buttons and links **/

.app-header .app-header-buttons {
	margin-left: 120px;
}
.app-header a,
.app-header a:hover,
.app-header a:active,
.app-header a:focus {
	color: white;
	text-decoration: none;
}
.app-header a.header-nav-button {
	margin: 0 14px;
}
.app-header a.header-nav-button:not(.btn) {
	margin-top: 3px;
	border-bottom: 3px solid transparent;
}
.app-header  a.header-nav-button:not(.btn):hover {
	border-bottom: 3px solid #fff;
}
.app-header a.header-nav-button.btn {
	height: 34px;
	font-size: 14px;
}
.app-header a.header-nav-button.btn-outline-light:hover {
	color: var(--app-black);
}


/** user dropdown **/

.app-header .vio-dropdown-button {
	border: none;
	background-color: transparent;
	font-size: 14px;
	padding: 0;
}
.app-header .vio-dropdown-container {
	font-size: 14px;
}
.app-header .vio-dropdown-button .bi-person {
	font-size: 16px;
}


/** cart link **/

.app-header .cart-link {
	font-size: 22px;
	margin-right: 15px;
}

.app-header .cart-link:hover {
	color: var(--bs-primary);
}

.cart-link-wrapper {
	position: relative;
}

.cart-link-wrapper .badge {
	position: absolute;
	right: 6px;
	top: -2;
	border-radius: 100%;
	font-weight: normal;
}



/** right section **/

.app-header .app-header-right {
	display: flex;
	align-items: center;
}
.app-header .mobile-search-link {
	font-size: 22px;
}


/** desktop search section **/
.app-header .app-header-desktop-search {
	display: flex;	
}


/* desktop layout */
@media (min-width: 901px) {
	.app-header {
		padding-right: 10px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	
}