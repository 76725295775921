.calendar-page {
	max-height: 100%;
	overflow: auto;
	padding: 20px;
}


.calendar-page .fc-daygrid-block-event {
	cursor: pointer;
}

.calendar-page a:not(.fc-daygrid-block-event):hover {
	text-decoration: none;
}

/* desktop layout */
@media (min-width: 901px) {
}

/* mobile layout */
@media (max-width: 900px) {
	.calendar-page {
		padding: 10px;
	}
}
