.first-load-tasks-card-container .first-load-tasks-card-body {
	display: flex;
	flex-wrap: wrap;
}

.first-load-tasks-card-container .first-load-taks-item {
	max-width: 160px;
	border: 1px solid var(--border-color);
	padding:  10px;
	margin: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 114px;
	border-radius: 3px;
}

.first-load-tasks-card-container .first-load-taks-item.in-progress {
	background-color: #e2e3e5;
	border-color: #e2e3e5;
}

.first-load-tasks-card-container .first-load-taks-item.complete {
	background-color: #d4edda;
	border-color: #c3e6cb;
}

.first-load-tasks-card-container .first-load-taks-item .task-name {
	text-align: center;
	margin-bottom: 12px;
}

.first-load-tasks-card-container .first-load-taks-item .task-name .data-set-label {
	font-weight: bold;
}
.first-load-tasks-card-container .first-load-taks-item .task-name .data-source-label {
	font-size: 12px;
}

.first-load-tasks-card-container .first-load-taks-item .task-status-icon .bi-check-lg {
	color: var(--bs-success);
	font-size: 24px;
}

.dark-mode .first-load-tasks-card-container .task-alert-row {
	background-color: transparent;
}

.first-load-tasks-card-container .task-times {
	font-size: 12px;
	display: flex;
	justify-content: space-between;
}
.first-load-tasks-card-container .task-time {
	line-height: 1;
}

.first-load-tasks-card-container .task-alert-row .task-time-label{
	display: block;
}

.dark-mode .first-load-tasks-card-container .task-alert-row.alert-secondary {
	color: var(--dark-mode-color);
}

.first-load-tasks-card-container .task-alert-row {
	width: 100%;
	padding-top: 6px;
	padding-bottom: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.first-load-tasks-card-container .task-alert-row .task-alert-icon {
	margin-right: 10px;
}

.first-load-tasks-card-container .task-alert-row .task-alert-icon .spinner-container {
	display: inline-block;
}


/* desktop layout */
@media (min-width: 901px) {
	.first-load-tasks-card-container .task-time {
		width: 120px;
	}
}

/* mobile layout */
@media (max-width: 900px) {

	.first-load-tasks-card-container .task-alert-row {
		padding: 5px 8px 5px 8px;
		display: block;
	}

}

