.building-info {
	background-color: white;
	border: 1px solid var(--border-color);
	/*border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;*/
	/*border-radius: calc(.65rem - 1px) calc(.65rem - 1px) 0 0;*/
	border-radius: calc(.65rem - 1px);
	/*padding: 17px 25px 0px 10px;*/
	padding: 12px 10px 0px 10px;
	/*padding: 20px 10px;*/
	/*padding-bottom: 0;*/
	margin: 10px;
	margin-top: 8px;
	margin-right: 20px;
}

.dark-mode .building-info {
	background-color: var(--dark-mode-bg);
	color: var(--dark-mode-color);
}
.dark-mode .building-info .building-info-item {
	background-color: var(--dark-mode-bg-2);
	color: var(--dark-mode-color);
}

.building-info h2 {
	margin: 0;
	font-weight: bold;
	/*margin-left: 10px;*/
	margin-left: 5px;
	/*padding-bottom: 4px;*/
	font-size: 16px !important;
	/*border-bottom: 1px solid var(--border-color);*/
}

.building-info-section {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	/*padding: 0 10px;*/
	margin-bottom: 10px;
	margin-top: 0px;
}
.building-info .representative-section .rep-name-wrapper {
	margin-left: 0;
}

.building-info .rep-icons .acris-wrapper, 
.building-info .rep-icons a {
	border-bottom: 2px solid transparent;
}
.building-info .rep-icons .acris-wrapper:hover,
.building-info .rep-icons a:hover {
	border-bottom: 2px solid var(--bs-primary);
}
.building-info .rep-icons .acris-wrapper button:hover {
	text-decoration: none;
}
.building-info .btn-hyperlink {
	font-size: 13px;
}

.building-info .rep-icons img {
	height: 16px;
	margin: 0 5px;
}
.building-info .rep-icons img.img-dof {
	height: 16px;
	margin: 0 2px;
}
.building-info .rep-icons img:hover {
/*	border-bottom: 2px solid white;*/
}

.building-info .building-info-item .building-info-field {
	/*font-weight: bold;*/
	margin-right: 5px;
}
.building-info .building-info-item .building-info-value {
	font-weight: bold;
}

.building-general-info {
	margin-bottom: 4px;
	margin-top: 0px;
}

.building-general-info .building-info-item {
	display: flex;
	/*justify-content: space-between;*/
	margin: 10px;
	/*border-bottom: 1px solid var(--border-color);*/
	padding: 3px 0px;
}

.building-info .building-zoning-info {
	margin-bottom: 8px;
}

/*.building-info .building-zoning-info .building-info-item {
	margin: 10px;
	border-bottom: 1px solid var(--border-color);
	padding: 3px 0px;
}*/

.building-info .representative-section {
/*	margin-top: 10px;*/
/*	margin-bottom: 16px;*/
	flex-direction: column;
	padding-left: 5px;
}
.building-info .representative-section .view-all-reps {
	margin-left: 10px;
}
.building-info .representative-section .view-all-reps a:hover{
	text-decoration: none;
}

.building-info .representative-section .representative-section-header {
	font-size: 16px;
	border-bottom: 1px solid var(--border-color);
	margin-bottom: 10px;
}

.building-info .representative-section .representative-name {
	font-size: 16px;
	/*font-weight: bold;*/
}

.building-info .representative-section .representatives-link-wrapper {
	font-size: 13px;
}
.building-info .representative-section h2 {
	margin-left: 0;
}
.building-info .representative-section div:not(:first-child) {
	margin-left: 5px;
}

.building-info .info-sections-wrapper {
	display: flex;
}

/* this uses different dimensions than everything else!
   it breaks to mobile mode much wider, because everything doesn't fit otherwise
 */
/* desktop layout */
@media (min-width: 1231px) {
	.building-info .info-sections-wrapper  {
		display: block;
	}
	.building-general-info .building-info-column {
		/*width: calc(100% / 3);*/
/*		width: calc(100% / 5);*/
		width: calc(100% / 6);
	}
	.building-zoning-info .building-info-column {
		width: calc(100% / 6);
	}
	.building-zoning-info .building-info-item .building-info-value {
		/*display: block;*/
	}

	.building-zoning-info .building-info-item .building-info-field {
		/*display: block;*/
	}

	.building-info .building-info-item {
		border-radius: 5px;
		background-color: #eff2f5;
		padding: 5px 10px;
		margin: 10px 5px;
		white-space: nowrap;
	}

	.building-info .rep-name-wrapper {
		display: flex;
	}
}

/* custom breakpoint for phone width */
@media (max-width: 400px) {
	.building-info .info-sections-wrapper {
		display: block;
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.building-info {
		margin: 0;
	}

	.building-info .property-image-wrapper {
		margin-left: 5px;
	}

	.building-info h2 {
		margin-left: 10px;
	}

/*	.building-zoning-info .building-info-column {
		width: 100%;
	}
	.building-zoning-info .building-info-item {
		display: flex;
		justify-content: space-between;
	}
*/

	.building-info .info-sections-wrapper>div {
		margin-top: 16px;
	}

	.building-info .building-info-top {
/*		margin-top: 5px;
		margin-bottom: 10px;
		padding-left: 10px;*/
		display: flex;
		flex-direction: row;
		justify-content: left;
		padding-left: 5px;
		margin-bottom: 5px;
	}

	.building-info .representative-section {
/*		margin-top: 5px;*/
		margin-bottom: 10px;
		padding-left: 10px;
		max-width: calc(100% - 85px);
		margin-top: -4px;
/*		display: flex;
		flex-direction: column;
		justify-content: center;*/
	}

	.building-info .property-image {
		width: 80px;
	}

	.building-info .rep-icons a:first-child img {
		margin-left: 0;
	}

	.building-info .representative-section .view-all-reps {
		display: block;
		margin-left: 0;
		margin-top: 5px;
	}

	.building-info .representative-section .representative-name {
		font-size: 13px;
	}

	.building-info .building-info-column {
		width: 100%;
	}
	.building-info .building-info-item {
		margin: 0 10px;
		border-bottom: 1px solid var(--border-color);
		padding: 0;
	}
}