
.amounts-due-card table td:not(:first-child) {
	text-align: right;
}

/*this is supposed to be a bootstrap built-in... */
.amounts-due-card .font-weight-bold  {
	font-weight: bold;
}


.dark-mode .amounts-due-card table tr:not(.amounts-due-row-above-total) td {
	border-color: var(--dark-mode-border-color);
}


/* desktop layout */
@media (min-width: 1231px) {
	.amounts-due-card {
		
	}
}

/* mobile layout */
@media (max-width: 1230px) {
	.amounts-due-card {
		
	}

}