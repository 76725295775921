.dashboard {
	max-height: calc(100% - var(--app-header-height));
	overflow: auto;
}

.dashboard .nav-item {
	cursor: pointer;
}

.dashboard-content {
	/*margin: auto;*/
	/*margin: 40px;*/
	/*margin-top: 40px;*/
	margin-top: 20px;
	/*max-width: 800px;*/
	margin-bottom: 60px;
}

.dashboard-content h1 {
	/*margin-bottom: 40px;*/
	margin-bottom: 20px;
}

.dashboard .contact-info {
	margin-top: 80px;
	text-align: center;
}

.dashboard .card {
	margin-bottom: 40px;
}

.dashboard .card .spinner-container {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background: rgba(255, 255, 255, 0.9);
}
.dark-mode .dashboard .card .spinner-container {
	background: rgba(32, 32, 32, 0.97);
}

.dashboard .card-body {
	position: relative;
}

.dashboard .dashboard-table-buttons-cell {
	min-width: 214px;
}

.dashboard .dashboard-date-time-cell {
	min-width: 175px;
}

.dashboard .dashboard-username-cell {
	min-width: 120px;
}

.dashboard .dashboard-notification-type-cell {
	min-width: 140px;
}


/* desktop layout */
@media (min-width: 901px) {
	.dashboard .pagination {
		border: none;
		padding: 0;
	}

	.dashboard-content h4 {
		margin-bottom: 20px;
	}

	.dashboard-content {
		/*margin: auto;*/
		margin: 20px 40px;
	}
}

/* mobile layout */
@media (max-width: 900px) {
	.dashboard-table-wrapper {
		overflow-x: auto;
	}
	.dashboard-content h4 {
		margin-bottom: 10px;
		margin-left: 10px;
	}

	.dashboard .export-link {
		margin-left: 10px;
	}

	.dashboard .nav-tabs .nav-link {
		margin-bottom: -3px;
		padding: 5px 8px;
		font-size: 12px;
	}

	.dashboard-content {
		margin-top: 10px;
	}

	.dashboard .card {
		border-radius: 0;
		border-left: none;
		border-right: none;
	}

	.dashboard .card-body {
		padding: 0px;
	}

	.dashboard-table-wrapper table {
		/*width: 500px;*/
	}

	.dashboard-table-wrapper table tr td:first-child,
	.dashboard-table-wrapper table tr th:first-child {
		padding-left: 20px;
	}

	.dashboard .dashboard-table-property-address-cell {
		min-width: 170px;
	}
}