:root {
	--border-color: #d6d6d6;
	--border-radius: calc(0.65rem - 1px);
	--text-black: #212529;
	--app-black: #212529;
	--app-header-height: 56px;
	--app-toolbar-height: 56px;



	--border-color: #EFF2F5;
	--text-black: #3F4254;
	--app-black: #3F4254;
	--bs-body-color: #3F4254;

	--bs-blue: #009ef6;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-gray: #7E8299;
	--bs-gray-dark: #3F4254;
	--bs-gray-100: #F5F8FA;
	--bs-gray-200: #EFF2F5;
	--bs-gray-300: #E4E6EF;
	--bs-gray-400: #B5B5C3;
	--bs-gray-500: #A1A5B7;
	--bs-gray-600: #7E8299;
	--bs-gray-700: #5E6278;
	--bs-gray-800: #3F4254;
	--bs-gray-900: #181C32;
	--bs-white: #ffffff;
	--bs-light: #F5F8FA;
	--bs-primary: #009EF7;
	--bs-secondary: #E4E6EF;
	--bs-success: #50CD89;
	--bs-info: #7239EA;
	--bs-warning: #FFC700;
	--bs-danger: #F1416C;
	--bs-dark: #181C32;
	--bs-white-rgb: 255, 255, 255;
	--bs-light-rgb: 245, 248, 250;
	--bs-primary-rgb: 0, 158, 247;
	--bs-secondary-rgb: 228, 230, 239;
	--bs-success-rgb: 80, 205, 137;
	--bs-info-rgb: 114, 57, 234;
	--bs-warning-rgb: 255, 199, 0;
	--bs-danger-rgb: 241, 65, 108;
	--bs-dark-rgb: 24, 28, 50;
	--bs-white-rgb: 255, 255, 255;
	--bs-black-rgb: 0, 0, 0;
	--bs-body-color-rgb: 24, 28, 50;
	--bs-body-bg-rgb: 255, 255, 255;

	--bs-light-primary: #F1FAFF;
	--bs-active-primary: #0095E8;
	--bs-light-success: #E8FFF3;
	--bs-active-success: #47BE7D;
	--bs-light-info: #F8F5FF;
	--bs-active-info: #5014D0;
	--bs-light-warning: #FFF8DD;
	--bs-active-warning: #F1BC00;
	--bs-light-danger: #FFF5F8;
	--bs-active-danger: #D9214E;
	--bs-light-dark: #EFF2F5;
	--bs-active-dark: #131628;
	--bs-gray-100: #F5F8FA;
	--bs-gray-200: #EFF2F5;
	--bs-gray-300: #E4E6EF;
	--bs-gray-400: #B5B5C3;
	--bs-gray-500: #A1A5B7;
	--bs-gray-600: #7E8299;
	--bs-gray-700: #5E6278;
	--bs-gray-800: #3F4254;
	--bs-gray-900: #181C32;
	--bs-xs: 0;
	--bs-sm: 576px;
	--bs-md: 768px;
	--bs-lg: 992px;
	--bs-xl: 1200px;
	--bs-xxl: 1400px;
	--bs-border-color: #EFF2F5;
	--bs-border-dashed-color: #E4E6EF;


	--bs-secondary: #9ea2b4;
	--bs-light-secondary: #b8bcd4;
	--bs-active-secondary: #888b9b;

	--bs-success: #47BE7D;
	--bs-active-success: #25b164;

	
}

a {
	color: var(--bs-primary);
}
a:hover {
	color: var(--bs-active-primary);
}

.card {
	border-radius: 0.65rem;
	border: none;
}

.card .card-body {
	padding-top: 5px;
}

.card .card-header {
	margin-bottom: 0;
	background-color: white;
	border-bottom: none;
	font-weight: bold;
	font-size: 16px;
}

.card .card-header:first-child {
	border-radius: calc(0.65rem - 1px) calc(0.65rem - 1px) 0 0;
}

.btn-primary {
	color: #fff;
	background-color: var(--bs-primary);
	border-color: var(--bs-primary);	
}
.btn-secondary {
	color: #fff;
	background-color: var(--bs-secondary);
	border-color: var(--bs-secondary);	
}
.btn-danger {
	color: #fff;
	background-color: var(--bs-danger);
	border-color: var(--bs-danger);	
}
.btn-success {
	color: #fff;
	background-color: var(--bs-success);
	border-color: var(--bs-success);	
}

.btn-outline-primary {
	color: var(--bs-primary);
	border-color: var(--bs-primary);	
}
.btn-outline-secondary {
	color: var(--bs-secondary);
	border-color: var(--bs-secondary);	
}
.btn-outline-danger {
	color: var(--bs-danger);
	border-color: var(--bs-danger);	
}
.btn-outline-success {
	color: var(--bs-success);
	border-color: var(--bs-success);	
}

.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:focus, 
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
	color: #fff;
	background-color: var(--bs-active-primary);
	border-color: var(--bs-active-primary);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:active,
.btn-outline-secondary:focus, 
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus {
	color: #fff;
	background-color: var(--bs-active-secondary);
	border-color: var(--bs-active-secondary);
}

.btn-outline-danger:hover,
.btn-outline-danger:active,
.btn-outline-danger:focus, 
.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
	color: #fff;
	background-color: var(--bs-active-danger);
	border-color: var(--bs-active-danger);
}

.btn-outline-success:hover,
.btn-outline-success:active,
.btn-outline-success:focus, 
.btn-success:hover,
.btn-success:active,
.btn-success:focus {
	color: #fff;
	background-color: var(--bs-active-success);
	border-color: var(--bs-active-success);
}

.btn-link {
	color: var(--bs-primary);
}
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
	color: var(--bs-active-primary);
}

.alert-success {
	background-color: var(--bs-light-success);
	border-color: var(--bs-success);
	color: var(--bs-active-success);
}



